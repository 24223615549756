
//PFP 
//ROTATION, LEFT/RIGHT, UP/DOWN 
const gacJetpack = [-20,160,246] 
const baycJetpack = [-16,200,110] 
const sacJetpack = [0,232,119] 
const dawJetpack = [0,230,59] 
const tracJetpack = [0,230,164]  

//BACKGROUND //ROTATION, LEFT/RIGHT, UP/DOWN 
const gacJetpackBG = [-20,31,190] 
const baycJetpackBG = [-16,-32,54] 
const sacJetpackBG = [0,0,60]
const dawJetpackBG = [0,0,0] 
const tracJetpackBG = [0,0,105]

const contractAddresses = [
    '0xa08b319f0f09ae8261db2034d43bf40c673f0ad0',
    '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
    '0x60e4d786628fea6478f785a6d7e704777c86a7c6',
    '0x22c08c358f62f35b742d023bf2faf67e30e5376e',
    '0xae63b956f7c77fba04e2eea59f7b8b2280f55431',
    '0x4b103d07c18798365946e76845edc6b565779402',
    '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e',
    '0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5',
    '0x1b1bff222999bcd6fd07b64d7880e6a95d54acaa',
    '0x2d0d57d004f82e9f4471caa8b9f8b1965a814154',
    '0x2120d19431e0dd49411e5412629f8e41a72cfabd',
    '0xe9626ef8dc3a8bb6bf9e18b44d35266a7ba3d11d',
    '0xe83dd605b70b47c8af86580bdd4fcb987ff36e60',
    '0x87212aa99f65611f6d67e0fbad76d06478753704',
	'0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2',
	'0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0',
	'0xfa969c60a78195c631787d4585ba15a07578c979']
     //Array of addresses of supported ape collections

  const contractNames = ['trac','bayc','mayc','0xapes','0xmayc','gacc','daw','SAC','layc','payc','jbas','arc','btfd','baddies','btyc','lion','aas']

const drawToCanvas = async (image,context) => {

    const { loadImage } = require(`canvas`);

    return new Promise(async (resolve, reject) => {

    getImage(image).then((value) => {

        console.log(value)

        loadImage(value).then((url) => {
            context.drawImage(url, 0, 0, 3000, 3000)
            resolve()
        }).catch((error) => {
            console.log("ERROR: FILE DOES NOT EXIST: "+image)
            console.log(error)
            resolve()
        })
})

})
}

const getMetadataTrait = (data,_trait) => {
  let _value = ''
  data.attributes.forEach((atr) => {
    try {
    if (atr.key.toLowerCase() === _trait) {
      _value = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    } catch {
      if (atr.trait_type.toLowerCase() === _trait) {
      _value = atr.value.toLowerCase().replace(/\s/g, '').replace("'", '').replace("-", '')
    }
    }
  })
    
  return _value
}

const getImage = async (filepath) => {

        let data = await fetch('https://fierce-retreat-72442.herokuapp.com/api/getLayerURL/'+filepath).then((response) => response.json()).catch((error) => {
            console.log(error)
        })
        return data[0]

}

const letsGenerate = async (rideType,data,ctx,tokenid) => {

    let canvas;

  switch (rideType) {
  	case 'none':
  			generate(["pfp","leftarm","./layers/l5.png","torso","rightarm","./layers/r5.png","rightleg"], [1,5,7], rideType, 1, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'toycar':
  			generate(["pfp","ride1","leftarm","torso","rightarm","ride2","rightleg","lefthand","ride3","righthand"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'banana':
  			generate(["pfp","ride1","leftarm","torso","rightarm","ride2","righthand","lefthand","rightleg"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'submarine':
  			generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","ride2","righthand","lefthand"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'spider': case 'shark': case 'funke': 
  			await generate(["pfp","ride1","leftarm","torso","rightarm","ride2","righthand","lefthand"], [1,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'rocket':
  			generate(["pfp","ride1","leftarm","torso","rightarm","righthand","lefthand","ride2"], [1,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'motorboat':
  			generate(["pfp","ride1","leftarm","torso","rightarm","lefthand","righthand","ride2"], [1,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'quad':
  			generate(["pfp","ride1","leftarm","torso","rightarm","ride2","rightleg","righthand","lefthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'cruiser':
  			generate(["pfp","ride1","leftarm","torso","rightarm","lefthand","ride2","rightleg","ride3","righthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'trike': case 'jetski': 
  			generate(["pfp","ride1","leftarm","torso","rightarm","ride2","lefthand","rightleg","righthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'motocross':
  		generate(["pfp","ride1","leftarm","torso","rightarm","ride2","lefthand","rightleg","ride3","righthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'waterski':
  			 generate(["pfp","leftarm","torso","rightarm","rightleg","ride1","righthand","lefthand"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'airplane':
  			generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","lefthand","ride2","righthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'golfcart':
  			generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","righthand","lefthand","ride2"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'moonbuggy':
  			generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","ride2","righthand","lefthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'gokart':
  			generate(["pfp","ride1","leftarm","torso","ride2","rightarm","rightleg","lefthand","ride3","righthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'jetpack':
  			generate(["pfp","ride1","ride2","leftarm","torso","ride3","rightarm","ride4","rightleg","righthand","lefthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'ufo':
  			generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","lefthand","righthand","ride2"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'tank':
  			generate(["pfp","ride1","lefthand","righthand"], [0,0,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
    case 'aperide':
        generate(["pfp","lefthand","righthand","ride1"], [0,0,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
      break;
  	case 'tricycle':
  			generate(["pfp","ride1","leftarm","torso","rightarm","ride2","rightleg","lefthand","ride3","righthand"], [2,6,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'handcart':
  			generate(["pfp","ride1","ride2","righthand"], [0,0,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'chargingbull':
  			generate(["pfp","ride1","leftarm","lefthand","torso","ride2","rightarm","righthand","rightleg"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'skateboard': case 'surfboard': case 'snowboard':
  			generate(["pfp","leftarm","torso","rightarm","rightleg","righthand","lefthand","ride1"], [2,6,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'bananabox':
  			generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","ride2","righthand","lefthand"], [2,6,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'pinata':
  			generate(["pfp","leftarm","torso","ride1","lefthand","rightarm","righthand","rightleg"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
  	case 'beerroller':
  			generate(["pfp","leftarm","torso","ride1","rightarm","rightleg",'lefthand','righthand'], [2,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
  		break;
      case 'barrelcar':
        await generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [1,6,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
        break;
    case 'bigwheel':
        await generate(["pfp","leftarm","torso","ride1","rightleg","rightarm","righthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
        break;
    case 'bmx':
        await generate(["pfp","ride1","leftarm","torso","rightarm","ride2","rightleg","righthand","lefthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
        break;
    case 'broomstick':
        await generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [2,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
    break;
    case 'camper':
        await generate(["pfp","ride2","leftarm","torso","rightleg","ride1","rightarm","righthand","lefthand"], [1,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
        break;
      case 'dragon':
              await generate(["pfp","ride1","leftarm","torso","rightleg","ride2","rightarm","righthand","lefthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
      case 'cannon':
          await generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
          break;
      case 'canoe':
          await generate(["pfp","ride1","leftarm","torso","rightarm","ride2","righthand","lefthand"], [1,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
          break;
      case 'copcruiser':
          await generate(["pfp","leftarm","torso","rightarm","ride1","rightleg","righthand","lefthand"], [1,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
          break;
      case 'digger':
              await generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
    case 'escooter':
        generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [2,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
        break;
      case 'holograph':
              await generate(["pfp","leftarm","torso","ride1","rightarm","righthand","lefthand"], [1,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'grandpacruiser':
              await generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","ride2","righthand","lefthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'gorilla':
              await generate(["pfp","leftarm","torso","lefthand","ride1","rightarm","rightleg","righthand"], [1,6,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'quadhopper':
              await generate(["pfp","leftarm","torso","lefthand","ride1","rightarm","rightleg","righthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'racingbike':
              await generate(["pfp","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
          break;
          case 'rockinghorse':
              await generate(["pfp","leftarm","torso","lefthand","ride1","rightarm","rightleg","righthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
          break;
          case 'sailboat':
              await generate(["pfp","ride1","leftarm","torso","rightarm","rightleg","ride2","lefthand","righthand"], [1,6,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'scooter':
              await generate(["pfp","leftarm","torso","lefthand","rightarm","ride1","rightleg","righthand"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'shoppingcart':
              await generate(["pfp","ride2","leftarm","torso","ride1","rightarm","rightleg","righthand","lefthand"], [2,6,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'snowmobile':
              await generate(["pfp","leftarm","torso","lefthand","rightarm","righthand","ride1","rightleg","ride2"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'spaceship':
              await generate(["pfp","ride1","leftarm","torso","rightarm","righthand","ride2","lefthand"], [1,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'superbike':
              await generate(["pfp","leftarm","torso","rightarm","ride1","rightleg","righthand","ride2"], [1,5,7], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'tractor':
              await generate(["pfp","ride1","leftarm","torso","ride2","rightarm","rightleg","righthand","lefthand"], [1,5,8], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
              break;
          case 'vintageracer':
              await generate(["pfp","ride1","leftarm","torso","lefthand","ride2","rightarm","righthand"], [2,5,0], rideType, tokenid, ctx, canvas, data).then((gStatus) => {console.log(gStatus);return gStatus;})
          break;
          default:
          break;
  	
  }

}

//PATHS - ["pfp","ride1","leftarm","torso","rightarm","ride2","rightleg","lefthand","righthand"]
//FORMAT - [leftarm, rightarm, rightleg] - [1,5,7]
const generate = async (paths, format, rideType, rideID, context, canvas, data, counter=0, tokenid=0) => {
    
    console.log(`Generating new layer at path index ${counter}\nPaths array = ${paths}`)
	
	const { loadImage } = require(`canvas`);
 	var fileToDraw;
    var x;
 	const basePath = contractNames[contractAddresses.indexOf(data.contract.toLowerCase())]+"/"
    console.log(basePath)
 	let furBasePath;
  	const clothingBasePath = basePath+"clothing/"+data.clothing.replace("&","_")+"/"
  	let noFeet = false;

  	if (data.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0" && data.fur === "chameleon") {
  		 furBasePath = basePath+"fur/"+data.fur+"/"+getMetadataTrait(data.data,'background')+"/"
  	} else {
  		  	 furBasePath = basePath+"fur/"+data.fur+"/"
  	}

  	if((data.clothing === "spacesuit" || data.clothing === "m1spacesuit" || data.clothing === "m2spacesuit" || data.contract==="0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2") && data.contract!=="0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5") {
  		noFeet = true;
  	}
    
    if (data.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0") {
        let mutation = await getMetadataTrait(data.data, 'mutation')
        if (mutation!==''){
            console.log(mutation)
            furBasePath = basePath+"mutants/"+mutation+"/"
            data.background = furBasePath+"bg.png"
        }
    } else if (data.contract === "0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5") {
        let mutation = await getMetadataTrait(data.data, 'legendary')
        if (mutation!==''){
            console.log(mutation)
            furBasePath = basePath+"legendary/"+mutation+"/"
            data.background = furBasePath+"bg.png"
        }
    } else if (data.contract === "0x4b103d07c18798365946e76845edc6b565779402") {
        let mutation = await getMetadataTrait(data.data, 'legendary grandpa')
        if (mutation!==''){
            console.log(mutation)
            furBasePath = basePath+"legendarygrandpa/"+mutation+"/"
            data.background = furBasePath+"bg.png"
        }
    } else if (data.contract === "0xfa969c60a78195c631787d4585ba15a07578c979") {
        if (data.fur==='legendarytype2'){
            console.log(data.fur)
            furBasePath = basePath+"legendary/"+data.fur+"/"
            data.background = furBasePath+"bg.png"
        }
    }

 	if (counter<paths.length) {

 		//MAIN GENERATION

 		if (counter === 0) {
 			await context.clearRect(0,0,3000,3000);
 		}

 		switch (paths[counter]) {
 			//torso and left leg
 			case "torso":
 			if (format[2]!==0) {

 				if (data.contract === "0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5")  {
 					if (data.back) {
 						await drawToCanvas(basePath+"back/"+data.back+".png",context).then(async ()=>{
	 						await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
			 					await drawToCanvas(furBasePath+"4.png",context).then(async ()=>{
			 					  await drawToCanvas(clothingBasePath+"3.png",context).then(async ()=>{

			 					  	if (!noFeet) {

				 						await drawToCanvas(basePath+"feet/l1.png",context).then(async ()=>{
				 						  await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{

				 						  	if (data.neck){
				 						  		await drawToCanvas(basePath+"neck/"+data.neck+".png",context).then(async ()=>{
				 						  			x = counter+1
							        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 						  		})
				 						  	} else {
				 						  		x = counter+1
							        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 						  	}

		 								  })
				 						})

			 						} else {
			 							await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{

				 						  	if (data.neck){
				 						  		await drawToCanvas(basePath+"neck/"+data.neck+".png",context).then(async ()=>{
				 						  			x = counter+1
							        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 						  		})
				 						  	} else {
				 						  		x = counter+1
							        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 						  	}

		 								  })
			 						}


			 					  })
			 					})
			 				})
			 			})

 					} else {
 						await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
		 					await drawToCanvas(furBasePath+"4.png",context).then(async ()=>{
		 					  await drawToCanvas(clothingBasePath+"3.png",context).then(async ()=>{
		 						await drawToCanvas(basePath+"feet/l1.png",context).then(async ()=>{
		 						  await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{

		 						  	if (data.neck){
		 						  		await drawToCanvas(basePath+"neck/"+data.neck+".png",context).then(async ()=>{
		 						  			x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
		 						  		})
		 						  	} else {
		 						  		x = counter+1
					        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
		 						  	}
 					})
		 			})
		 			})
		 			})
		 			})
 					}
                } else if (data.contract === "0x60e4d786628fea6478f785a6d7e704777c86a7c6" || data.contract === "0xae63b956f7c77fba04e2eea59f7b8b2280f55431") {
                    
                    await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
                        await drawToCanvas(clothingBasePath+"11.png",context).then(async ()=>{
                            await drawToCanvas(furBasePath+"4.png",context).then(async ()=>{
                                await drawToCanvas(clothingBasePath+"3.png",context).then(async ()=>{
                                    
                                    if (!noFeet) {

                                        await drawToCanvas(basePath+"feet/l1.png",context).then(async ()=>{
                                            await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{
                                                x = counter+1
                                                await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
                                            })
                                        })

                                    } else {
                                        await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{
                                            x = counter+1
                                             await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
                                        })
                                    }
                                })
                            })
                        })
                    })
                    
                } else {
 				await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
 					await drawToCanvas(furBasePath+"4.png",context).then(async ()=>{
 					  await drawToCanvas(clothingBasePath+"3.png",context).then(async ()=>{
 					  	if (!noFeet) {
 					  	
 						await drawToCanvas(basePath+"feet/l1.png",context).then(async ()=>{
 						  await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{




			 				if (data.contract.toLowerCase() === "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e") {





				 				if (data.necklace !== '') {
				 					await drawToCanvas(basePath+"necklace/"+data.necklace+".png",context).then(async ()=>{
				 						if (data.hair !== '') {
				 							await drawToCanvas(basePath+"hair/"+data.hair+".png",context).then(async ()=>{
				 								if (data.handbag !== '') {
				 									await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 										x = counter+1
					        							await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 				})
									 			} else {
									 				x = counter+1
					        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 			}
									 		})
									 	} else if (data.handbag !== '') {
				 							await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 								x = counter+1
					        					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 		})
									 	} else {
									 		x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 	}
									 })
								} else
								if (data.hair !== '') {
									 await drawToCanvas(basePath+"hair/"+data.hair+".png",context).then(async ()=>{
									 	if (data.handbag !== '') {
									 		await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
									 			x = counter+1
					        					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 		})
									 	} else {
									 		x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 	}
									 })
								} else
								if (data.handbag !== '') {
									 await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
									 		x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 })
								} else {
									x = counter+1
					        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
								}

			 				}  else if (data.contract.toLowerCase() === "0xfa969c60a78195c631787d4585ba15a07578c979") {
								if (data.face) {
									await drawToCanvas(basePath+"face/"+data.face+".png",context).then(async ()=>{
											x = counter+1
										   await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									})
							   }
							 } else {
			 					x = counter+1
			        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
			        		}




        	  			  })
			  			})
 				} else {

 					await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{



			 				if (data.contract.toLowerCase() === "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e") {

				 				if (data.necklace !== '') {
				 					await drawToCanvas(basePath+"necklace/"+data.necklace+".png",context).then(async ()=>{
				 						if (data.hair !== '') {
				 							await drawToCanvas(basePath+"hair/"+data.hair+".png",context).then(async ()=>{
				 								if (data.handbag !== '') {
				 									await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 										x = counter+1
					        							await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 				})
									 			} else {
									 				x = counter+1
					        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 			}
									 		})
									 	} else if (data.handbag !== '') {
				 							await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 								x = counter+1
					        					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 		})
									 	} else {
									 		x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 	}
									 })
								} else
								if (data.hair !== '') {
									 await drawToCanvas(basePath+"hair/"+data.hair+".png",context).then(async ()=>{
									 	if (data.handbag !== '') {
									 		await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
									 			x = counter+1
					        					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 		})
									 	} else {
									 		x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 	}
									 })
								} else
								if (data.handbag !== '') {
									 await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
									 		x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
									 })
								} else {
									x = counter+1
					        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
								}

			 				} else {
			 					x = counter+1
			        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
			        		}





        	  			  })
 				}
                          
                          
                          
                          
                          
			  	  	})
 			  	  })
 			    })
 			}
             
                //no legs here
 			} else {

 				if (data.contract === "0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5")  {
 					if (data.back) {
 						await drawToCanvas(basePath+"back/"+data.back+".png",context).then(async ()=>{
	 						await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
			 						  await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{

			 						  	if (data.neck){
			 						  		await drawToCanvas(basePath+"neck/"+data.neck+".png",context).then(async ()=>{
			 						  			x = counter+1
						        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
			 						  		})
			 						  	} else {
			 						  		x = counter+1
						        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
			 						  	}

	 								  })
			 						})
			 					  
			 					
			 				
			 			})

 					} else {
 						await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
		 						  await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{

		 						  	if (data.neck){
		 						  		await drawToCanvas(basePath+"neck/"+data.neck+".png",context).then(async ()=>{
		 						  			x = counter+1
					        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
		 						  		})
		 						  	} else {
		 						  		x = counter+1
					        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
		 						  	}
 					})
		 			})
		 			
		 			
		 			
 					}
 				} else {

 				await drawToCanvas(furBasePath+"3.png",context).then(async ()=>{
 					await drawToCanvas(clothingBasePath+"4.png",context).then(async ()=>{
			 			if (data.contract.toLowerCase() === "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e") {
				 			if (data.hair !== '') {
				 				await drawToCanvas(basePath+"hair/"+data.hair+".png",context).then(async ()=>{
				 					if (data.necklace !== '') {
				 						await drawToCanvas(basePath+"necklace/"+data.necklace+".png",context).then(async ()=>{
				 							if (data.handbag !== '') {
				 								await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 									x = counter+1
        											await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 								})
				 							} else {
				 								x = counter+1
					        					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 							}
				 						})
				 					} else if (data.handbag !== '') {
				 						await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 							x = counter+1
        									await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 						})
				 					} else {
				 						x = counter+1
					        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 					}
				 				})
				 			} else
				 			if (data.necklace !== '') {
				 				await drawToCanvas(basePath+"necklace/"+data.necklace+".png",context).then(async ()=>{
				 					if (data.handbag !== '') {
				 						await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 							x = counter+1
        									await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 						})
				 					} else {
				 						x = counter+1
					        			await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 					}
				 				})
				 			} else
				 			if (data.handbag !== '') {
				 				await drawToCanvas(basePath+"handbags/"+data.handbag+".png",context).then(async ()=>{
				 						x = counter+1
        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 				})
				 			} else {
				 				x = counter+1
					        	await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				 			}
			 			} else {
 							x = counter+1
        					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        				}
        			})
				})
 			  }
 			}
 			break;
 			//left arm
 			case "leftarm":
 				if (data.contract === "0x60e4d786628fea6478f785a6d7e704777c86a7c6" || data.contract === "0xae63b956f7c77fba04e2eea59f7b8b2280f55431") {
 					await drawToCanvas(clothingBasePath+(8+format[0]).toString()+".png",context).then(async ()=>{
 						await drawToCanvas(furBasePath+format[0]+".png",context).then(async ()=>{
 					await drawToCanvas(clothingBasePath+format[0]+".png",context).then(async ()=>{
 						x = counter+1
        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        			})
        		})
 					})
 				} else {
 				await drawToCanvas(furBasePath+format[0]+".png",context).then(async ()=>{
 					await drawToCanvas(clothingBasePath+format[0]+".png",context).then(async ()=>{
 						x = counter+1
        				await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        			})
        		})
 			}
 			break;
 			//right arm
 			case "rightarm":
 				if (data.contract === "0x60e4d786628fea6478f785a6d7e704777c86a7c6" || data.contract === "0xae63b956f7c77fba04e2eea59f7b8b2280f55431") {
 					await drawToCanvas(clothingBasePath+(7+format[1]).toString()+".png",context).then(async ()=>{

 						await drawToCanvas(furBasePath+format[1]+".png",context).then(async ()=>{
 				await drawToCanvas(clothingBasePath+format[1]+".png",context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        	})})})
                } else {
 				await drawToCanvas(furBasePath+format[1]+".png",context).then(async ()=>{
 				await drawToCanvas(clothingBasePath+format[1]+".png",context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
                
                })})}
 			break;
 			//right leg
 			case "rightleg":
 				if (format[2]!==0) {
 					if (data.contract === "0x60e4d786628fea6478f785a6d7e704777c86a7c6" || data.contract === "0xae63b956f7c77fba04e2eea59f7b8b2280f55431") {
 						await drawToCanvas(clothingBasePath+(7+format[2]).toString()+".png",context).then(async ()=>{
 							await drawToCanvas(furBasePath+format[2]+".png",context).then(async ()=>{
 								await drawToCanvas(clothingBasePath+format[2]+".png",context).then(async ()=>{
 									if (!noFeet) {
	 									if (format[2]===7) {
	 										await drawToCanvas(basePath+"feet/r2.png",context).then(async ()=>{
	 											x = counter+1
	        									await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
	        								})
	 									} else if (format[2]===8){
	 										await drawToCanvas(basePath+"feet/r1.png",context).then(async ()=>{
	 											x = counter+1
	        									await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
	 										})
	 									}
	 								} else {
	 									x = counter+1
	        							await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
	 								}
 								})
 							})
 						})
 					} else {
 						await drawToCanvas(furBasePath+format[2]+".png",context).then(async ()=>{
 							await drawToCanvas(clothingBasePath+format[2]+".png",context).then(async ()=>{
 								if (!noFeet) {
	 								if (format[2]===7) {
	 									await drawToCanvas(basePath+"feet/r2.png",context).then(async ()=>{
	 										x = counter+1
	        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
	        							})
	 								} else if (format[2]===8){
	 									await drawToCanvas(basePath+"feet/r1.png",context).then(async ()=>{
	 										x = counter+1
	        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
	 									})
	 								}
	 							} else {
	 								x = counter+1
	        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
	 							}
 							})
 						})
 					}
 				} else {
 					x = counter+1
	        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
 				}
 			break;
 			//pfp and background
 			case "pfp":
 				if (rideType==='jetpack') {
 					context.save()
 					context.translate(1500,1500);
 					let rotationValues;
 					let bgValues;
 					switch (data.contract) {
 						case '0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5':
 							rotationValues = sacJetpack;
 							bgValues = sacJetpackBG;
 						break;
 						case '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e':
 							rotationValues = dawJetpack;
 							bgValues = dawJetpackBG;
 						break;
 						case '0x4b103d07c18798365946e76845edc6b565779402':
 							rotationValues = gacJetpack;
 							bgValues = gacJetpackBG;
 						break;
 						case '0xa08b319f0f09ae8261db2034d43bf40c673f0ad0':
 							rotationValues = tracJetpack;
 							bgValues = tracJetpackBG;
 						break;
 						default:
 							rotationValues = baycJetpack;
 							bgValues = baycJetpackBG;
 						break;
 					}
 					context.rotate(rotationValues[0]*Math.PI/180);


                    //edit from this link. Chhange loadimage funcs to:
                    /*
                    let img = new Image()
                    img.crossOrigin="anonymous"
                    img.src = _image;
                    img.onload = () => {
                      context.drawImage(img, 0, 0, 3000, 3000)
                      resolve()
                    }

                    img.onerror = () => { 
                        console.log("ERROR: FILE DOES NOT EXIST: "+_image)
                        resolve()
                    }*/





 					loadImage(data.url).then(async(image) => {
 						if (data.contract.toLowerCase() === "0x4b103d07c18798365946e76845edc6b565779402") {
 							context.drawImage(image,-720-rotationValues[1],-1440-rotationValues[2],1440,1440)
 						} else {
        			context.drawImage(image,-720-rotationValues[1],-1440-rotationValues[2],1440,1440)
        		}
        		console.log("loaded pfp - "+data.url)
                let _databg = await getImage(data.background)
        		loadImage(_databg).then(async(image2) => {
        				await context.drawImage(image2,-2250-bgValues[1],-2250-bgValues[2],4500,4500)
        				context.restore();
        				console.log("background drawn")
                        context.save()
                        context.rotate(bgValues[0]*Math.PI/180);
                        context.translate(-bgValues[1],-bgValues[2])
        				if (data.contract === "0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5" && data.mouth) {
        					await drawToCanvas(basePath+"mouth/"+data.mouth+".png",context).then(async ()=>{
                                context.restore()
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        					})
        					
        				} else if (data.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0"){
        					if(data.mouth==="beerkeg"){
        						await drawToCanvas(basePath+"beerkeg.png",context).then(async ()=>{
        							if (getMetadataTrait(data.data,'head')==="antenna") {
        								await drawToCanvas(basePath+"antenna.png",context).then(async ()=>{
                                        context.restore()
        								x = counter+1
        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        								})
        							} else {
                                        context.restore()
        								x = counter+1
        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        							}
        					})
        					} else if (getMetadataTrait(data.data,'head')==="antenna") {
        						await drawToCanvas(basePath+"antenna.png",context).then(async ()=>{
                                context.restore()
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        					})
        					} else {
                                context.restore()
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        					}
        				} else {
                                context.restore()
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        				}

        			}).catch(async(error) => { 
        				console.log("ERROR: BACKGROUND DOES NOT EXIST: "+data.background)
      				})
        		
 					}).catch((error) => {
      				console.log("\n\nPFP ERROR SERVER FETCH FAILED. \n" +data.url)
      		})
 				} else {
 				loadImage(data.url).then(async(image) => {
 					if (data.contract.toLowerCase() === "0x4b103d07c18798365946e76845edc6b565779402") {
 						context.drawImage(image,651,4,1440,1440)
 					} else if (data.contract.toLowerCase() === "0xe83dd605b70b47c8af86580bdd4fcb987ff36e60") {
            context.drawImage(image,406,4,1440,1440)
          }else if (data.contract.toLowerCase() === "0x87212aa99f65611f6d67e0fbad76d06478753704") {
            context.drawImage(image,511,5,1440,1440)
          } else if (data.contract.toLowerCase() === "0xfa969c60a78195c631787d4585ba15a07578c979") {
			context.drawImage(image,633,5,1440,1440)
		  } else {
        				context.drawImage(image,549,4,1440,1440)
        			}
        			console.log("loaded pfp - "+data.url)
                    let _databg = await getImage(data.background)
        			loadImage(_databg).then(async(image2) => {
        				await context.drawImage(image2,-750,-750,4500,4500)
        				console.log("background drawn")
        				if (data.contract === "0x984f7b398d577c0adde08293a53ae9d3b6b7a5c5" && data.mouth) {
        					await drawToCanvas(basePath+"mouth/"+data.mouth+".png",context).then(async ()=>{
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        					})
        					
        				} else if (data.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0"){
        					if(data.mouth==="beerkeg"){
        						await drawToCanvas(basePath+"beerkeg.png",context).then(async ()=>{
        							if (getMetadataTrait(data.data,'head')==="antenna") {
        								await drawToCanvas(basePath+"antenna.png",context).then(async ()=>{
        								x = counter+1
        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        								})
        							} else {
        								x = counter+1
        								await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        							}
        					})
        					} else if (getMetadataTrait(data.data,'head')==="antenna") {
        						await drawToCanvas(basePath+"antenna.png",context).then(async ()=>{
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        					})
        					} else {
        						x = counter+1
        						await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        					}
        				} else if (data.contract === "0x87212aa99f65611f6d67e0fbad76d06478753704") {
                  if(data.accessory !== "noaccessory") {
                    await drawToCanvas(basePath+"accessories/"+data.accessory+".png",context).then(async ()=>{
                    x = counter+1
                    await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
                  })
                  } else {
                    x = counter+1
                    await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
                  }
                } else {
					x = counter+1
					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        		}

        			}).catch(async(error) => { 
        				console.log("ERROR: BACKGROUND DOES NOT EXIST: "+data.background)
      				})
      				
      			}).catch((error) => {
      				console.log("\n\nPFP ERROR SERVER FETCH FAILED. \n" +data.url)
      			})
      		}
 			break;
 			//ride layers
 			case "ride1":
 				await drawToCanvas("rides/layer1/"+rideID+".png",context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        	})
 			break;
 			case "ride2":
 				await drawToCanvas("rides/layer2/"+rideID+".png",context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        	})
 			break;
 			case "ride3":
 				await drawToCanvas("rides/layer3/"+rideID+".png",context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        	})
 			break;
 			case "ride4":
 				await drawToCanvas("rides/layer4/"+rideID+".png",context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        	})
 			break;
 			case "righthand":
 			fileToDraw = "rides/"+rideType+"/hands/"
 				switch (data.contract) {
 					case "0x60e4d786628fea6478f785a6d7e704777c86a7c6": case "0xae63b956f7c77fba04e2eea59f7b8b2280f55431":
 						fileToDraw = fileToDraw + "mr.png"
 					break;
 					case "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e": case "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0":
 						fileToDraw = fileToDraw + "wr.png"
 					break;
                    case "0x2d0d57d004f82e9f4471caa8b9f8b1965a814154":
                        fileToDraw = fileToDraw + "pr.png"
                    break;
                    case "0x87212aa99f65611f6d67e0fbad76d06478753704":
                      fileToDraw = fileToDraw + "br.png"
                    break;
					case "0xfa969c60a78195c631787d4585ba15a07578c979":
						fileToDraw = fileToDraw + "aar.png"
					break;
 					default:
 						fileToDraw = fileToDraw + "r.png"
 					break;
 				}
				if (data.contract==='0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2') {
					x = counter+1
					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				} else {
					await drawToCanvas(fileToDraw, context).then(async ()=>{
					x = counter+1
					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        		})
				}
 			break;
 			case "lefthand":
 				fileToDraw = "rides/"+rideType+"/hands/"
 				switch (data.contract) {
 					case "0x60e4d786628fea6478f785a6d7e704777c86a7c6": case "0xae63b956f7c77fba04e2eea59f7b8b2280f55431":
 						fileToDraw = fileToDraw + "ml.png"
 					break;
 					case "0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e": case "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0":
 						fileToDraw = fileToDraw + "wl.png"
 					break;
                    case "0x2d0d57d004f82e9f4471caa8b9f8b1965a814154":
                        fileToDraw = fileToDraw + "pl.png"
                    break;
                    case "0x87212aa99f65611f6d67e0fbad76d06478753704":
                      fileToDraw = fileToDraw + "bl.png"
                    break;
					case "0xfa969c60a78195c631787d4585ba15a07578c979":
						fileToDraw = fileToDraw + "aal.png"
					break;
 					default:
 						fileToDraw = fileToDraw + "l.png"
 					break;
 				}
				if (data.contract==='0xbc4e8115e17d96cfd8e3ecef2b0d6e19d00f70b2') {
					x = counter+1
					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
				} else {
					await drawToCanvas(fileToDraw, context).then(async ()=>{
					x = counter+1
					await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
					})
				}
 			break;
 			default:
 				await drawToCanvas(paths[counter],context).then(async ()=>{
 				x = counter+1
        		await generate(paths,format, rideType, rideID, context,canvas,data, x,tokenid)
        	})
 			break;


 		}

 	} else {
        console.log(rideType)
        if (rideType === 'holograph') {
            console.log("holograph")
            await drawToCanvas("rides/"+rideType+"/holograms/"+data.contract.toLowerCase()+".png", context).then(async ()=>{
        

 		if (data.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0" && data.fur === "cryo") {
 			await drawToCanvas(furBasePath+"9.png",context).then(async ()=>{
               loadImage("https://storage.googleapis.com/aperidesnfts/assets/PREVIEW.png").then(async(image) => {
                   // await context.drawImage(image,0,0,3000,3000)
                    document.getElementById('canvasHider').style.display = "none";
                })

 			})
 		} else {
 			//Generation finished
            console.log("generation complete")
            loadImage("https://storage.googleapis.com/aperidesnfts/assets/PREVIEW.png").then(async(image) => {
               // await context.drawImage(image,0,0,3000,3000)
                document.getElementById('canvasHider').style.display = "none";
            })


      	}

            })
        } else {

if (data.contract === "0xa08b319f0f09ae8261db2034d43bf40c673f0ad0" && data.fur === "cryo") {
            await drawToCanvas(furBasePath+"9.png",context).then(async ()=>{
                loadImage("https://storage.googleapis.com/aperidesnfts/assets/PREVIEW.png").then(async(image) => {
                    //await context.drawImage(image,0,0,3000,3000)
                    document.getElementById('canvasHider').style.display = "none";
                })

 //done
            })
        } else {
            //Generation finished
            console.log('yyee')
            loadImage("https://storage.googleapis.com/aperidesnfts/assets/PREVIEW.png").then(async(image) => {
                //await context.drawImage(image,0,0,3000,3000)
                document.getElementById('canvasHider').style.display = "none";
            })

        }

        }

 	}
    

}

export default letsGenerate
